<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">

        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tablePositions.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_unit"
            >
              <strong>
                Unit
              </strong>
            </label>
            <b-select
              id="filter_unit"
              v-model="filter.unit"
              :options="filterUnits"
              :disabled="state.busy"
              @change="onRefreshTablePositions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-button
            type="button"
            variant="primary"
            @click="onShowAddFormPosition"
          >
            Add Position
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tablePositions"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tablePositions.perPage"
        :current-page="tablePositions.currentPage"
        :items="myProvider"
        :fields="tablePositions.fields"
        :sort-by.sync="tablePositions.sortBy"
        :sort-desc.sync="tablePositions.sortDesc"
        :sort-direction="tablePositions.sortDirection"
        :filter="tablePositions.filter"
        :filter-included-fields="tablePositions.filterOn"
      >
        <template #cell(index)="row">
          {{ tablePositions.currentPage * tablePositions.perPage - tablePositions.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell(position_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditFormPosition(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePositions.perPage"
              :options="tablePositions.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePositions.currentPage"
            :total-rows="tablePositions.totalRows"
            :per-page="tablePositions.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-position"
      scrollable
      no-close-on-backdrop
      :title="modalPositionTitle"
      aria-hidden="false"
      @ok="onValidateFormPosition"
    >
      <ValidationObserver
        ref="formPosition"
      >
        <form
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="unit"
            vid="unit"
            rules="required"
          >
            <b-form-group>
              <label for="unit">
                <strong>
                  Unit
                </strong>
              </label>
              <b-form-select
                id="unit"
                v-model="position.unit"
                :options="list.units"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- select unit --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position name"
            vid="position_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="position_name">
                <strong>
                  Position
                </strong>
              </label>
              <b-input
                id="position_name"
                v-model="position.position_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter position name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="position.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
              switch
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import {
  AdminPositionService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminPositions',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Positions'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      filter: {
        unit: 'All'
      },
      list: {
        units: []
      },
      position: {
        id: null,
        unit: null,
        position_name: null,
        active: 0
      },
      tablePositions: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'unit.unit_name', label: 'unit' },
          { key: 'position_name', label: 'position', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalPositionTitle () {
      return this.state.editing ? 'Edit Position' : 'Add Position'
    },

    filterUnits () {
      return [{ text: 'All', value: 'All' }].concat(this.list.units)
    }
  },

  mounted () {
    core.index()
    this.getUnits()
  },

  methods: {
    async myProvider (ctx) {
      return await AdminPositionService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_unit: this.filter.unit
        })
      ).then(({ data }) => {
        this.tablePositions.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onRefreshTablePositions () {
      this.$refs.tablePositions.refresh()
    },

    async getUnits () {
      SharedListService.getUnits().then(({ data }) => {
        this.list.units = data.map(({ id, unit_name }) => ({
          text: unit_name,
          value: id
        }))
      })
    },

    onShowAddFormPosition () {
      this.state.editing = false
      this.position.id = null
      this.position.unit = null
      this.position.position_name = null
      this.position.active = 1
      this.$bvModal.show('modal-position')
    },

    onShowEditFormPosition (position) {
      this.state.editing = true
      this.position.id = position.id
      this.position.unit = position.unit_id
      this.position.position_name = position.position_name
      this.position.active = position.active
      this.$bvModal.show('modal-position')
    },

    async onValidateFormPosition (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formPosition.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create position?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormPosition()
              } else {
                return this.onPostFormPosition()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostFormPosition () {
      return new Promise(resolve => {
        AdminPositionService.post(this.position).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-position')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tablePositions.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formPosition.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onPutFormPosition () {
      return new Promise(resolve => {
        AdminPositionService.put(this.position).then(({ data }) => {
          this.$bvModal.hide('modal-position')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tablePositions.localItems, { id: data.position.id })
            row.unit = data.position.unit
            row.unit_id = data.position.unit_id
            row.position_name = data.position.position_name
            row.active = data.position.active
            row.updated_at = data.position.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formPosition.setErrors(error.message)
          resolve(error)
        })
      })
    }
  }
}
</script>
